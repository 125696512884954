import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Crumb, Button, Select, Loader } from "../Components";
import { GetSalesTaxReport } from "../services";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { format } from "d3-format";
import "./SalesTaxReport.css";

export const stateAbbreviationToLabel = {
  CA: "California",
  ID: "Idaho",
  NV: "Nevada",
};

export const stateLabelToAbbreviation = {
  California: "CA",
  Idaho: "ID",
  Nevada: "NV",
};

export default function SalesTaxReport() {
  const [state, setState] = useState("California");

  const [startDate, setStartDate] = useState(
    moment().subtract(1, "month").startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(1, "month").endOf("month").toDate()
  );
  const [report, setReport] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    try {
      setReport(null);
      setIsLoading(true);
      console.log("state", state);
      const stateAbbrev = stateLabelToAbbreviation[state];
      console.log(stateAbbrev);
      const { data } = await GetSalesTaxReport({
        start,
        end,
        state: stateAbbrev,
      });
      setIsLoading(false);
      setReport(data);
    } catch (e) {
      console.error(e);
    }
  };

  console.log("report", report);
  const formatCurrency = (value) => {
    if (value === 0) return "$0.00";
    else if (value.includes(".")) return "$" + format(",.2f")(value);
    return value;
  };

  return (
    <Container>
      <Crumb trail={[["Tools", "/auth/tools"], ["Sales Tax Report"]]} />
      <h3>Sales Tax Report</h3>
      <div style={{ marginBottom: 40 }}>
        <div style={{ marginBottom: 12 }}>
          <div>State</div>
          <Select
            label={state}
            options={["California", "Idaho", "Nevada"]}
            value={state}
            setValue={setState}
            containerStyle={{ marginLeft: 0 }}
          />
        </div>
        <div style={{ marginBottom: 12 }}>
          <div>Date Range</div>
          <DatePicker
            className="daterange-input"
            selected={startDate}
            onChange={(range) => {
              setStartDate(range[0]);
              setEndDate(range[1]);
            }}
            startDate={startDate}
            endDate={endDate}
            selectsRange
          />
        </div>
        <Button
          filled
          title="Get Report"
          style={{ marginTop: 20 }}
          onClick={handleSubmit}
        />
      </div>
      {isLoading && <Loader />}
      {report && (
        <>
          <table style={{ marginBottom: 40 }}>
            <tbody>
              {Object.keys(report).map((key) => (
                <tr key={key}>
                  <td style={{ fontWeight: "500", paddingRight: 30 }}>{key}</td>
                  <td>{formatCurrency(report[key])}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <table className="sales-tax-report-table">
            <thead>
              <tr>
                <th>State</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Total Sales</th>
                <th>Total Nontaxable</th>
                <th>Total Taxable</th>
                <th>Total Tax Collected</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{stateAbbreviationToLabel[report.State]}</td>
                <td>{moment(report["Start"]).format("MM/DD/YYYY")}</td>
                <td>{moment(report["End"]).format("MM/DD/YYYY")}</td>
                <td>{formatCurrency(report["Total Sales"])}</td>
                <td>{formatCurrency(report["Total Nontaxable Sales"])}</td>
                <td>{formatCurrency(report["Total Taxable Sales"])}</td>
                <td>{formatCurrency(report["Total Tax Collected"])}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </Container>
  );
}
